.viewNavBar{
  width: 100%;
  background-color: #2d5085;
}

.viewNavBar-brand{
  color: white !important;
}

.viewNavBar-dropdown-title {
  color: white !important;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
  padding-top: .325rem;
  padding-bottom: .3125rem;
}

.viewNavBar-dropdown {
  margin-right: 10px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.viewNavBar-dropdown-formgroup {
  margin-bottom: 0;
  font-size: 14px;
  display: inline-block;
}

.viewNavBar-dropdown-formgroup > .form-control {
  padding: 0;
}

.viewNavBar-logout-btn {
  color: white;
  border-color: white;
  background-color: #2d5085;
}

.viewNavBar-logout-btn:hover {
  border-color: white;
  background-color: #5d98f0;
}

#sticky-sidebar{
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

@media(min-width: 768px) {
    #sticky-sidebar{
        max-width: fit-content;
    }
}


@media(max-width: 768px) {
    #sticky-sidebar{
        padding-top: 10px;
        width: 100% !important;    /* Set the width of the sidebar*/
        overflow-y: hidden;     /* Disable horizontal scroll */
    }
}

.dropright .dropdown-toggle{
    border-radius: 50%;
    margin-bottom: 0.5rem;
    width: 3.5rem;
    height: 3.5rem;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
}

.sidebar-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

@media(min-width: 768px) {
    .sidebar-list {
        display: inline-block;
    }
}

.sidebar-list-col {

}

@media(max-width: 768px) {
    .sidebar-list-col {
        text-align: center;
    }
}

.sidebar-btn, .dropdown-btn-parent > button {
  color: #2d5085;
  border-color: #2d5085;
  background-color: white;
}

.sidebar-btn:hover, .dropdown-btn-parent > button:hover {
  background-color: #5d98f0;
  cursor: pointer;
}

#main-canvas{
    padding-left: 0;
    padding-right: 0;
}

/************************/

.sideBar{
    /* position: fixed !important;     Fixed Sidebar (stay in place on scroll and position relative to viewport) */
    height: 100% !important;
    width: 75px !important;    /* Set the width of the sidebar*/
    z-index: 1 !important;      /* Stay on top of everything */
    background-color: rgb(39, 37, 37); /* Black */
    overflow-x: hidden;     /* Disable horizontal scroll */
    padding-top: 10px;
}

@media(max-width: 600px) {
    .sideBar{
        margin-top: 4rem !important;
        height: 75px !important;
        width: 100% !important;    /* Set the width of the sidebar*/
        z-index: 1 !important;      /* Stay on top of everything */
        background-color: rgb(39, 37, 37); /* Black */
        overflow-y: hidden;     /* Disable horizontal scroll */
        padding-top: 10px;
    }

}


.dropdown-menu{
    z-index: 1000 !important;
}

.settingsPopoverList {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

#viewTypeContent {
  padding: 0;
}

#viewTypeContent > .row, #viewTypeContent > .row > .col {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

#viewTypeContent > .row > .col > button, #layoutPopover > .popover-body > .row > button  {
  width: 100%;
  border-radius: 0;
  background-color: #ebebeb;
  border-color: #ebebeb;
  color: #2d5085;
}

#viewTypeContent > .row > .col > .activeView, #layoutPopover > .popover-body > .row > .activeLayout  {
  width: 100%;
  border-radius: 0;
  background-color: #2d5085;
  border-color: #2d5085;
  color: white !important;
  box-shadow: none;
}

#viewTypeContent > .row > .col:first-child {
  border-right: 1px solid #ebebeb;
}

.viewSettingsPopoverContent {
  padding: 0;
}

.viewSettingsCardHeader {
  padding: 0;
}

.viewSettingsCardHeader > button, .viewSettingsCardHeader > button:hover {
  color: #212529;
  width: 100%;
}

.viewSettingsCardBody {
  padding: 0.5rem 0.75rem;
}

.viewSettingsPopoverButton {
  background-color: #2d5085;
  border-color: #2d5085;
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.875rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.vsDisabled, .vsDisabled:hover {
  cursor: not-allowed !important;
  background-color: rgb(170, 170, 170);
  border-color: rgb(170, 170, 170);
}

.settingsPopoverList > li > input[type='checkbox'] {
  float: right;
}

#layoutPopover > .popover-body {
  padding: 0;
}

#layoutPopover > .popover-body > .row {
  margin: 0;
}

.v-scroll{
    height: 86vh !important;
    overflow-y: scroll !important;
}

.v-scroll-mobile{
    height: 40vh !important;
    overflow-y: scroll !important;
}

.mrg-1-min{
    margin-top: -1rem !important;
}

.pd-2-right{
    padding-right: 2rem !important;
}

.scaffold-text{
    color: rgb(233, 174, 66) !important;
    font-weight: bold;
    width: 280px !important;
    padding: 0.25rem !important;
    border: solid;
    border-color: rgb(233, 174, 66);
    border-radius: 1rem !important;
    margin-bottom: 1rem !important;
    list-style: none !important;
}

.white{
    color: white !important;
}

.pd-left{
    padding-left : -2px !important;
}

.dropright .dropdown-toggle::after{
    display: none;
}

.dropright .dropdown-toggle{
    border-radius: 50%;
    margin-bottom: 0.5rem;
    width: 3.5rem;
    height: 3.5rem;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
}

.circle-button{
    border-radius: 50%;
    margin-bottom: 0.5rem;
    width: 3.5rem;
    height: 3.5rem;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);

}

.pad {
    padding-top: 12px;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
