body {
  height: 100vh;
}
.login-main {
    background-image: url("../../assets/world_map.png");
    height: 100vh;
}
.login-row {
  height: inherit;
  overflow: auto;
}

.login-col {
  padding-left: 0;
  padding-right: 0;
  background-color: rgba(107, 166, 214, 0.5);
}

.login-form-row {
  margin-left: 0;
  margin-right: 0;
}

.login-form-wrapper {
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  background-color: white;
  border: 1px solid #48535c;
  border-radius: 3px;
  padding: 5%;
  padding-bottom: 10px;
  max-width: inherit;
}

 @media (max-width: 768px){
   .login-form-wrapper {
     margin-top: 0;
     margin-bottom: 0;
     border: none;
     border-radius: 0px;
   }
}

.login-form-wrapper > h1 {
  font-size: 2.25rem;
  font-weight: 500;
}

.login-input-wrapper {
  border: 1px solid black;
  border-radius: 4px;
  padding-left: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-top:7px;
  padding-bottom: 7px;
}

.login-input-wrapper > input {
  border: none;
  width: 80%;
  padding-left: 5px;
}

.login-input-wrapper > input:focus {
  outline-width: 0;
}

.login-checkbox-wrapper {
  margin-top: 3%;
  margin-bottom: 3%;
}

.login-checkbox-wrapper > input[type=checkbox]:checked {
  background-color: #6ba6d6;
}

.login-checkbox-wrapper > label {
  padding-left: 5px;
}

.login-checkbox-wrapper > a {
  color: black;
  padding: 2px;
}

.login-checkbox-wrapper > input[type=checkbox]:hover, .checkBoxWrapper > a:hover {
  cursor: pointer;
}

.login-button {
  width: 100%;
  background-color: #6ba6d6;
  font-size: 1.1rem;
  letter-spacing: 2px;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
}

.login-button:hover {
  cursor: pointer;
  border: 2px solid black;
  padding: 6px;
}

.login-disabled {
  background-color: #95999c;
}

.login-disabled:hover {
  cursor: not-allowed;
}

.login-create-account-p {
  margin-top: 10px;
  margin-bottom: 0;
  left: 30%;
  right: 30%;
  text-align: center;
  font-size: .85rem;
}

.login-create-account-p:hover{
  text-decoration: underline;
  cursor: pointer;
}

.login-about {
  background-color: rgba(255, 255, 255, 0.95);
}

.login-about-wrapper {
  padding-top: 5%;
}

.login-about-wrapper > h1 {
  padding-top: 20px;
  padding-bottom: 5px;
  margin-left: 5%;
  letter-spacing: 2px;
  border-bottom: 2px solid #6ba6d6;
  display: inline-block;
}

.login-about-wrapper > p {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
}

@media (max-width: 768px){
  .login-about-wrapper > h1 {
    font-size: 1.75rem;
  }
}

@media (max-width: 1024px){
  .login-about-wrapper > p {
    font-size: 1.25rem;
  }
}

.login-overlay-container {
  position: relative;
  width: 50%;
  border-radius: 5px;
}

.login-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #0e386e;
  border-radius: 5px;
}

.login-overlay-container:hover .login-overlay {
  opacity: 0.85;
  cursor: pointer;
}

.login-overlay-text {
  color: white;
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-demo {
  width: -webkit-fill-available;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;
}

.login-demo-image {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.login-apps-div {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (max-width: 768px){
  .login-apps-div {
    background-color: white;
    margin-top: 0;
  }
}

.login-appstore-img{
  width: 115px;
  height: 50px;
}

.login-modal-dialog {
  max-width: 90% !important;
}

.login-modal-dialogfullscreen {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
  margin: 0;
}

.login-modal-dialogfullscreen > .modal-content {
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 0;
}

.login-modal-title {
  width: 100%;
}

.login-modal-body {
  height: 75vh;
  overflow: hidden;
}

.kf-demo-modaltitle-btn {
  float: right;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}

.kf-demo-modaltitle-btn:hover {
  cursor: pointer;
  color: black;
}

.login-modal-btn {
  width: auto;
}

.login-demoview {
  width: 100%;
  height: 100%;
}
