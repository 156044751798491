body {
  height: 100vh;
}

.main {
  padding: 0;
}

/*------------*/
/* SIDEBAR CSS FROM https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/css/simple-sidebar.css */
#wrapper {
    overflow-x: hidden;
    background-image: url("../../assets/world_map.png");
 }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
/*------------*/

.dashboard-navbar {
  background-color: white;
}

.dashboard-select-server, .dashboard-joinButton, .dashboard-enterButton {
  background-color: #2d5085;
  border: none;
  font-weight: 500;
}

.dashboard-select-server:hover, .dashboard-joinButton:hover, .dashboard-enterButton:hover {
  background-color: #5d98f0;
  cursor: pointer;
}

.dashboard-nav-link {
  font-weight: 500;
  font-size: 1.25rem;
  color: #203c66 !important;
}

.dashboard-currentInfo {
  margin-left: 10px;
  font-weight: 500;
  font-size: 1rem;
  color: #203c66 !important;
}

#page-content-wrapper {
  background-color: rgba(255, 255, 255, 0.95);
}

.dashboard-sidebar-custom {
  background-color: rgba(107, 166, 214, 0.5);
}

.dashboard-sidebar-heading {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  color: white;
  letter-spacing: 2px;
}

.dashboard-server-list {
  padding-left: 0;
}

.dashboard-server-list > li {
  list-style: none;
  color: white;
  font-weight: 500;
  font-size: 1.15rem;
  padding: 5px;
  padding-left: 10px;
  margin-left: 5px;
}

.dashboard-server-list > li:hover {
  cursor: pointer;
  background-color: #5d98f0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dashboard-viewDropDownContainer {
  border-top: 2px solid #fcf000;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-viewDropDownContainer > p {
  color: white;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}

.active {
  background-color: rgba(255, 255, 255, 0.9);
  color: #2d5085 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.active:hover {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

#dashboard-mainContentContainer  {
  height: calc(100vh - 65px);
}

#dashboard-mainContentContainer > .row {
  height: 100%;
}

.dashboard-mainContentCol > h1 {
  padding-top: 15px;
  letter-spacing: 2px;
  font-size: 2rem;
  color: #203c66 !important;
  max-height: 15%;
}

@media (max-width: 768px){
  .dashboard-mainContentCol > h1 {
    font-size: 1.25rem;
    letter-spacing: normal;
  }
}

.dashboard-joinCommunityForm {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-communityChoiceDropdown, .dashboard-joinCommunityForm > input {
  width: 95%;
}

.dashboard-joinCommunityForm > input {
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.dashboard-joinCommunityForm > input:focus {
  outline: 0;
  border-color: #2684FF;
  border-width: 2px;
}

.dashboard-joinCommunityForm > label {
  color: #203c66 !important;
  font-weight: 500;
  font-size: 1.25rem;
}

@media (max-width: 768px){
  .dashboard-joinCommunityForm > label {
    font-size: 1rem;
  }
}

.dashboard-joinButton, .dashboard-enterButton {
  font-size: 1rem; letter-spacing: 2px;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px;
  margin-top: 15px;
}

.dashboard-enterButton {
  margin-top: 0px;
  margin-left: 10px;
  float: right;
}

.loader {
  margin: 0 auto;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #203c66; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dashboard-userCommunities {
  height: 85%;
  width: 80%;
  padding-left: 10px;
  font-size: 1.25rem;
  font-weight: 500;
  color: #203c66 !important;
}

@media (max-width: 768px){
  .dashboard-userCommunities {
    font-size: 1rem;
    width: 100%;
  }
}

.dashboard-userCommunities > tbody {
  display: block;
  height: 100%;
  overflow-y: auto;
  padding-right: 17px;
}

.dashboard-userCommunities > tbody::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.dashboard-userCommunities > tbody::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.dashboard-userCommunities > tbody::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2d5085;
}

.dashboard-userCommunities > tbody > tr {
  display: table;
  width: 100%;
}


.dashboard-userCommunities > tbody > tr:hover {
  background-color: rgba(255, 255, 255);
  cursor: pointer;
}

.dashboard-userCommunities-row {
  padding: 10px;
}
