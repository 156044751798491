html, body {
  margin: 0;
  padding: 0;
}

#root, #sub-root {
  height: 100%
}

#container-fluid-for-view-js {
  height: 100%;
  overflow-y: hidden;
}

.__________cytoscape_container {
  background-image: none;
  background-color: white;
  height: 100vh;
  max-width: 100%;
}

.topBar{

}

#cy {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: block;
}

.cytoscape-label{
  font-size: 9px;
  margin-top: -7px;
  margin-left: -9px;
}

.cytoscape-icon{
  float: right;
}


/* Dropdown Button */
.dropbtn {
  background-color: #6ba6d6;
  color: white;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  border: none;
}

.dropbtn:hover {
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  cursor: pointer;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #234c6e;}
