.cy-panzoom {
	position: absolute;
	font-size: 12px;
	color: #fff;
	font-family: arial, helvetica, sans-serif;
	line-height: 1;
	color: #666;
	font-size: 11px;
	z-index: 99999;
	box-sizing: content-box;
  top: 18%;
	right: 55px;
}

.cy-panzoom-zoom-button {
	cursor: pointer;
	padding: 3px;
	text-align: center;
	position: absolute;
	border-radius: 3px;
	width: 10px;
	height: 10px;
	left: 16px;
	background: #fff;
	border: 1px solid #999;
	margin-left: -1px;
	margin-top: -1px;
	z-index: 1;
	box-sizing: content-box;
}

.cy-panzoom-zoom-button:active,
.cy-panzoom-slider-handle:active,
.cy-panzoom-slider-handle.active {
	background: #ddd;
	box-sizing: content-box;
}

.cy-panzoom-pan-button {
	position: absolute;
	z-index: 1;
	height: 16px;
	width: 16px;
	box-sizing: content-box;
}

.cy-panzoom-reset {
	top: 55px;
	box-sizing: content-box;
}

.cy-panzoom-zoom-in {
	top: 80px;
	box-sizing: content-box;
}

.cy-panzoom-zoom-out {
	top: 197px;
	box-sizing: content-box;
}

.cy-panzoom-pan-up {
	top: 0;
	left: 50%;
	margin-left: -5px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #666;
	box-sizing: content-box;
}

.cy-panzoom-pan-down {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #666;
	box-sizing: content-box;
}

.cy-panzoom-pan-left {
	top: 50%;
	left: 0;
	margin-top: -5px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-right: 5px solid #666;
	box-sizing: content-box;
}

.cy-panzoom-pan-right {
	top: 50%;
	right: 0;
	margin-top: -5px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #666;
	box-sizing: content-box;
}

.cy-panzoom-pan-indicator {
	position: absolute;
	left: 0;
	top: 0;
	width: 8px;
	height: 8px;
	border-radius: 8px;
	background: #000;
	border-radius: 8px;
	margin-left: -5px;
	margin-top: -5px;
	display: none;
	z-index: 999;
	opacity: 0.6;
	box-sizing: content-box;
}

.cy-panzoom-slider {
	position: absolute;
	top: 97px;
	left: 17px;
	height: 100px;
	width: 15px;
	box-sizing: content-box;
}

.cy-panzoom-slider-background {
	position: absolute;
	top: 0;
	width: 2px;
	height: 100px;
	left: 5px;
	background: #fff;
	border-left: 1px solid #999;
	border-right: 1px solid #999;
	box-sizing: content-box;
}

.cy-panzoom-slider-handle {
	position: absolute;
	width: 16px;
	height: 8px;
	background: #fff;
	border: 1px solid #999;
	border-radius: 2px;
	margin-left: -2px;
	z-index: 999;
	line-height: 8px;
	cursor: default;
	box-sizing: content-box;
}

.cy-panzoom-slider-handle .icon {
	margin: 0 4px;
	line-height: 10px;
	box-sizing: content-box;
}

.cy-panzoom-no-zoom-tick {
	position: absolute;
	background: #666;
	border: 1px solid #fff;
	border-radius: 2px;
	margin-left: -1px;
	width: 8px;
	height: 2px;
	left: 3px;
	z-index: 1;
	margin-top: 3px;
	box-sizing: content-box;
}

.cy-panzoom-panner {
	position: absolute;
	left: 5px;
	top: 5px;
	height: 40px;
	width: 40px;
	background: #fff;
	border: 1px solid #999;
	border-radius: 40px;
	margin-left: -1px;
	box-sizing: content-box;
}

.cy-panzoom-panner-handle {
	position: absolute;
	left: 0;
	top: 0;
	outline: none;
	height: 40px;
	width: 40px;
	position: absolute;
	z-index: 999;
	box-sizing: content-box;
}

.cy-panzoom-zoom-only .cy-panzoom-slider,
.cy-panzoom-zoom-only .cy-panzoom-panner {
	display: none;
}

.cy-panzoom-zoom-only .cy-panzoom-reset {
	top: 20px;
}

.cy-panzoom-zoom-only .cy-panzoom-zoom-in {
	top: 45px;
}

.cy-panzoom-zoom-only .cy-panzoom-zoom-out {
	top: 70px;
}
