.signup-select-server {
  padding-top: 1px;
  padding-bottom: 2px;
}

.fa-server {
  float: left;
  margin-top: 10px;
}

.select-container {
  float: left;
  width: 95%;
}

.clearFix {
  clear: both;
}

.select__value-container, .select__value-container--is-multi, .css-g1d714-ValueContainer {
  padding: 0 !important;
}

.select__control {
  border: none !important;
}

.select__control:focus, .select__control--is-focused, .select__control--menu-is-open {
  border: none !important;
  box-shadow: none !important;
}

.select__indicator, .select__indicator-separator {
  color: black !important;
}
