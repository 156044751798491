.attachment-thumbnail, .attachment-buttons {
    display: inline-block;
    vertical-align: top;
}

.attachment-thumbnail {
    width: 93px;
    height: 80px;
}

.attachment-thumbnail img {
    max-width: 93px;
    max-height: 80px;
    vertical-align: top;
}

.attachment-thumbnail .selected {
    border: 1px solid red;
}

.attachment-info p {
    margin: 0rem;
}

.attachment-buttons {
}

.attachment-box {
    margin: 5px;
    padding-right: 10px;
    vertical-align: top;

    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
}

.attachment-separator {
    border-right: solid 1px grey;
    vertical-align: top;
}

.delete-attachment, .download-attachment{
    background-color: transparent;
    border: 0px;
    margin-top: 0px;
    padding: 0px;
    font-size: 20px;
}
.attach-button {
    font-size: 0.8rem;
    padding: 0.1rem;
}
