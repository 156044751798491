@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

span {
  word-wrap:break-word !important;
}

/* MARGIN */

.mrg-0 {
  margin: 0rem !important;
}

.mrg-025 {
  margin: 0.25rem !important;
}
.mrg-05 {
  margin: 0.5rem !important;
}

.mrg-1 {
  margin: 1rem !important;
}

.mrg-2 {
  margin: 2rem !important;
}

.mrg-4 {
  margin: 4rem !important;
}

.mrg-8 {
  margin: 8rem !important;
}

.mrg-16 {
  margin: 16rem !important;
}

.mrg-32 {
  margin: 32rem !important;
}

.mrg-64 {
  margin: 64rem !important;
}

.mrg-05-top {
  margin-top: 0.5rem !important;
}

.mrg-1-top {
  margin-top: 1rem !important;
}

.mrg-105-top {
  margin-top: 1.5rem !important;
}

.mrg-2-top {
  margin-top: 2rem !important;
}

.mrg-4-top {
  margin-top: 4rem !important;
}

.mrg-6-top {
  margin-top: 6rem !important;
}

.mrg-8-top{
  margin-top: 6rem !important;
}

.mrg-16-top{
  margin-top: 16rem !important;
}

.mrg-32-top{
  margin-top: 32rem !important;
}

.mrg-0-bot{
  margin-bottom: 0rem !important;
}

.mrg-1-bot{
  margin-bottom: 1rem !important;
}

.mrg-105-bot{
  margin-bottom: 1.5rem !important;
}
.mrg-1-left{
  margin-left: 1rem !important;
}

.mrg-1-right{
  margin-right: 1rem !important;
}

/* PADDING */
.pd-0 {
  padding: 0rem !important;
}

.pd-05 {
  padding: 0.5rem !important;
}

.pd-1 {
  padding: 1rem !important;
}

.pd-2 {
  padding: 2rem !important;
}

.pd-4 {
  padding: 4rem !important;
}

.pd-8 {
  padding: 8rem !important;
}

.pd-16 {
  padding: 16rem !important;
}

.pd-32 {
  padding: 32rem !important;
}

.pd-64 {
  padding: 64rem !important;
}

.pd-2-right{
  padding-right: 2rem !important;
}

.pd-1-left {
  padding-left: 2rem !important;
}

.pd-2-left {
  padding-left: 2rem !important;
}

.pd-6-top {
  padding-top: 6rem !important;
}

.white {
  color: azure;
}

.indigo {
  color: indigo;
}

.blue {
  color: blue;
}

.pink-bg{
  background-color: palevioletred;
};

.light-bg{
  background-color: aqua;
};

.fix-col-size{
  max-height: 300px;
  overflow: auto;
}

.min-width{
  min-width: 400px !important;
  margin-right: 0;
}

.visible-1{
  z-index: 1 !important;
}

/* PRIMARY BACKGROUND */

.primary-bg-50{
  background-color: #FAFAFA !important;
}

.primary-bg-100{
  background-color: #F5F5F5 !important;
}

.primary-bg-200{
  background-color: #EEEEEE !important;
}

.primary-bg-300{
  background-color: #E0E0E0 !important;
}

.primary-bg-400{
  background-color: #BDBDBD !important;
}

.primary-bg-500{
  background-color: #9E9E9E !important;
}

.primary-bg-600{
  background-color: #757575 !important;
}

.primary-bg-700{
  background-color: #616161 !important;
}

.primary-bg-800{
  background-color: #424242 !important;
}

.primary-bg-900{
  background-color: #212121 !important;
}

/* PRIMARY FONT COLOR */
.primary-50{
  color: #FAFAFA !important;
}

.primary-100{
  color: #F5F5F5 !important;
}

.primary-200{
  color: #EEEEEE !important;
}

.primary-300{
  color: #E0E0E0 !important;
}

.primary-400{
  color: #BDBDBD !important;
}

.primary-500{
  color: #9E9E9E !important;
}

.primary-600{
  color: #757575 !important;
}

.primary-700{
  color: #616161 !important;
}

.primary-800{
  color: #424242 !important;
}

.primary-900{
  color: #212121 !important;
}




/* SIZE */

.sz-05{
  font-size: 0.5rem !important;
}

.sz-075{
  font-size: 0.75rem !important;
}

.sz-1{
  font-size: 1rem !important;
}

.sz-2{
  font-size: 2rem !important;
}

.sz-3{
  font-size: 3rem !important;
}


/* MINIMUM HEIGHT */
.min-height-2{
  min-height: 2rem;
}

.circular-border{
  border-radius: 50%;
}

.min-width-10{
  min-width: 10rem !important;
}


.fixed-height{
  max-height: 40vh !important;
  overflow-y: scroll !important;
}
